import React, {useState, useLayoutEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';

import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import Tabs from '../../components/tabs';
import abc from '../../img/reviews/abc.jpg';
import fox from '../../img/reviews/fox.png';
import marketwatch from '../../img/reviews/marketwatch.png';
import nbc from '../../img/reviews/nbc.png';
import ocbs from '../../img/reviews/ocbs.png';
import rikEl from '../../img/reviews/rik-el.png';
import rikElMobile from '../../img/reviews/rik-mobile.png';
import paul from '../../img/reviews/paul.png';
import mashesh from '../../img/reviews/mashesh.png';
import vivek from '../../img/reviews/vivek.png';
import daniel from '../../img/reviews/daniel.png';
import verified from '../../img/reviews/verified.png';
import aniket from '../../img/reviews/aniket.png';
import levon from '../../img/reviews/levon.png';
import sevada from '../../img/reviews/sevada.png';
import zorayr from '../../img/reviews/zorayr.png';
import kevin from '../../img/reviews/kevin.png';
import linus from '../../img/reviews/linus.png';
import mikayel from '../../img/reviews/mikayel.png';
import paulMobile from '../../img/reviews/paul-mobile.png';
import masheshMobile from '../../img/reviews/mashesh-mobile.png';
import vivekMobile from '../../img/reviews/vivek-mobile.png';
import danielMobile from '../../img/reviews/daniel-mobile.png';
import verifiedMobile from '../../img/reviews/verified-mobile.png';
import aniketMobile from '../../img/reviews/aniket-mobile.png';
import levonMobile from '../../img/reviews/levon-mobile.png';
import sevadaMobile from '../../img/reviews/sevada-mobile.png';
import zorayrMobile from '../../img/reviews/zorayr-mobile.png';
import kevinMobile from '../../img/reviews/kevin-mobile.png';
import linusMobile from '../../img/reviews/linus-mobile.png';
import mikayelMobile from '../../img/reviews/mikayel-mobile.png';
import benjamin from '../../img/reviews/benjamin.png';
import userEdu from '../../img/reviews/user-edu.png';
import dhiya from '../../img/reviews/dhiya.png';
import jorge from '../../img/reviews/jorge.png';
import hector from '../../img/reviews/hector.png';
import userGov from '../../img/reviews/user-gov.png';
import benjaminMobile from '../../img/reviews/benjamin-mobile.png';
import userEduMobile from '../../img/reviews/user-edu-mobile.png';
import dhiyaMobile from '../../img/reviews/dhiya-mobile.png';
import jorgeMobile from '../../img/reviews/jorge-mobile.png';
import hectorMobile from '../../img/reviews/hector-mobile.png';
import userGovMobile from '../../img/reviews/user-gov-mobile.png';
import capterraLogo from '../../img/reviews/capterra-logo.png';
import capterra from '../../img/ratings/capterra.svg';
import getApp from '../../img/ratings/getApp.svg';
import productHunt from '../../img/ratings/product-hunt.svg';
import g2 from '../../img/ratings/g2.svg';

import './styles.scss';

const ratings = [
  {
    name: 'GetApp',
    image: getApp,
    value: '4.8/5',
    linkTo: 'https://www.getapp.com/website-ecommerce-software/a/hexometer/',
  },
  {
    name: 'Capterra',
    image: capterra,
    value: '4.8/5',
    linkTo: 'https://www.capterra.com/p/198619/Hexometer/reviews/',
  },
  {
    name: 'Product Hunt',
    image: productHunt,
    value: '4.9/5',
    linkTo: 'https://www.producthunt.com/posts/hexometer-2',
  },
  {
    name: 'G2.com',
    image: g2,
    value: '4.8/5',
    linkTo:
      'https://www.g2.com/products/hexometer/reviews?__cf_chl_jschl_tk__=3ca982c5f6b76f56b7dcfffe3923c0af659ca984-1599738892-0-ASvYCSGB6_EWtBYuu_eeUv8D-dp1P0Gf0eQC7jJ8acorMP5lItkuFXnmC2p3dTm16uAi_QmIBoZ-hjhQLg-0LCKQmZ2CjCWpYXCBHFdcymv7rQZRXjVy3iOj4zSqPcVNFfm5duH-yxRucpbWpJs0n3wEPxCqEIyL3ATSkQUb1fMrEUcMuRDdKYKXLfl01CuuaOzkrNzi5Euaf8uRKBsdeONTJ2ScShlIhTxsTW-C-vBn7eN21qysxCh-zmJ3UFRhJWmegWUEy-2kuWPUEA5-pXVaaZaNkB1AI4gRMYIWYUcF_2mDZojx5-Kx5kNTtPtJkA#survey-response-4360759',
  },
];

const ReviewsPress = () => {
  const [width, setWidth] = useState<any>(null);

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth < 767) {
        setWidth(window.innerWidth);
      } else {
        setWidth(null);
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <Layout>
      <PageHero
        cls={'pd-bottom-0'}
        title="Reviews & In The Press"
        subtitle={`<p>
          Hexometer helps our 10000+ clients get peace of mind, working 24/7 detecting and helping
          fix website health and performance problems before they burn a hole in their pocket.
        </p>
        <p>
          Every day our AI assistant crawls over 12,000,000 pages, checking pages for downtime,
          page speed performance issues, security vulnerabilities, JS errors, email deliverability,
          SEO optimization or server configuration problems.
        </p>`}
      />
      <Section about>
        <Tabs />
        <div className="col-12 d-block mb_20 reviews-press" style={{maxWidth: '1200px', width: '100%'}}>
          <div className="featured-on-block">
            <h3 className="separator">Featured On</h3>
            <div className="wrapper">
              <div className="img-block-wrapper img-shadow">
                <div className="img-block">
                  <div className="w-100">
                    <img src={abc} alt="abc" />
                  </div>
                </div>
              </div>

              <div className="img-block-wrapper img-shadow">
                <div className="img-block">
                  <div className="w-100">
                    <img src={nbc} alt="nbc" />
                  </div>
                </div>
              </div>
              <div className="img-block-wrapper img-shadow">
                <div className="img-block">
                  <div className="w-200">
                    <img src={ocbs} alt="ocbs" />
                  </div>
                </div>
              </div>

              <div className="img-block-wrapper img-shadow">
                <div className="img-block">
                  <div className="w-200" style={{maxWidth: '130px'}}>
                    <img src={fox} alt="fox" />
                  </div>
                </div>
              </div>

              <div className="img-block-wrapper img-shadow">
                <div className="img-block">
                  <div className="w-200">
                    <img src={marketwatch} alt="marketwatch" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>
            But one thing we love most of all is hearing your feedback and stories which inspire our team to improve and
            expand our features to better protect your websites.
          </p>

          <div className="row">
            {ratings.map(item => (
              <div
                key={item.name}
                className="col-md-3 col-sm-6 col-xs-6 text-center rating-block"
                style={{textAlign: 'start'}}
              >
                <a href={item.linkTo} target="_blank" rel="nofollow noopener">
                  <img
                    src={item.image}
                    alt={item.name}
                    height={20}
                    width={item.name === 'G2.com' ? 25 : 84}
                    style={{height: '20px', width: item.name === 'G2.com' ? '25px' : '84px'}}
                  />
                  <span className="f_700 ml-2">{item.value}</span>
                  <ul className="d-flex p-0" style={{justifyContent: 'center'}}>
                    <li className="stars">
                      <FontAwesomeIcon icon={faStar} color="#f3ad16" />
                    </li>
                    <li className="stars">
                      <FontAwesomeIcon icon={faStar} color="#f3ad16" />
                    </li>
                    <li className="stars">
                      <FontAwesomeIcon icon={faStar} color="#f3ad16" />
                    </li>
                    <li className="stars">
                      <FontAwesomeIcon icon={faStar} color="#f3ad16" />
                    </li>
                    <li className="stars">
                      <FontAwesomeIcon icon={faStar} color="#f3ad16" />
                    </li>
                  </ul>
                </a>
              </div>
            ))}
          </div>

          <h3 className="title">
            Latest reviews from
            <img src={capterraLogo} alt="" className={'captera-logo'} />
          </h3>

          <div className="img-block-fluid  mb-20">
            <img src={!width ? rikEl : rikElMobile} alt="image" />
          </div>
          <div className="img-block-fluid mb-20">
            <img src={!width ? paul : paulMobile} alt="image" />
          </div>

          <div className="img-block-fluid mb-20">
            <img src={!width ? mashesh : masheshMobile} alt="image" />
          </div>
          <div className="img-block-fluid mb-20">
            <img src={!width ? vivek : vivekMobile} alt="image" />
          </div>
          <div className="img-block-fluid mb-20">
            <img src={!width ? daniel : danielMobile} alt="image" />
          </div>
          <div className="img-block-fluid mb-20">
            <img src={!width ? verified : verifiedMobile} alt="image" />
          </div>
          <div className="img-block-fluid mb-20">
            <img src={!width ? aniket : aniketMobile} alt="image" />
          </div>
          <div className="img-block-fluid mb-20">
            <img src={!width ? levon : levonMobile} alt="image" />
          </div>
          <div className="img-block-fluid mb-20">
            <img src={!width ? sevada : sevadaMobile} alt="image" />
          </div>
          <div className="img-block-fluid mb-20">
            <img src={!width ? zorayr : zorayrMobile} alt="image" />
          </div>
          <div className="img-block-fluid mb-20">
            <img src={!width ? kevin : kevinMobile} alt="image" />
          </div>
          <div className="img-block-fluid mb-20">
            <img src={!width ? mikayel : mikayelMobile} alt="image" />
          </div>
          <div className="img-block-fluid mb-20">
            <img src={!width ? linus : linusMobile} alt="image" />
          </div>

          <h3 className="title">Latest reviews from G2.com</h3>

          <div className="img-block-fluid precent mb-20">
            <img src={!width ? benjamin : benjaminMobile} alt="image" />
          </div>
          <div className="img-block-fluid precent mb-20">
            <img src={!width ? userEdu : userEduMobile} alt="image" />
          </div>
          <div className="img-block-fluid precent mb-20">
            <img src={!width ? dhiya : dhiyaMobile} alt="image" />
          </div>
          <div className="img-block-fluid precent mb-20">
            <img src={!width ? jorge : jorgeMobile} alt="image" />
          </div>
          <div className="img-block-fluid precent mb-20">
            <img src={!width ? hector : hectorMobile} alt="image" />
          </div>
          <div className="img-block-fluid precent mb-20">
            <img src={!width ? userGov : userGovMobile} alt="image" />
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default ReviewsPress;
